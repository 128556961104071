<template>
  <el-container>
      <el-main  :style="{height: contentHeight + 'px','margin-top':contentMargin + 'px'}">
        <div v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" style="height: 90%;width: 1500px;margin:0 auto">
          <ul id="gameListUl" class="infinite-list" style="overflow:auto;height: 100%">
            <li @click="goDetail(data.id)" v-for="(data,index) in datas" class="infinite-list-item">
              <div @mouseover="mouseoverImage(index)" @mouseleave="mouseleaveImage" style="padding: 10px; width: 230px;height: 128px;">
                <div style="overflow: hidden">
                  <img :class="[hoverIndex==index?'zoomed-in':'zoomed-out']" :src="data.bgUrl" width="100%" height="100%" >
                </div>
              </div>
              <div style="font-size: 14px;text-align: left;height: 20px;padding: 0 10px 0 10px;">
                <a @click="goDetail(data.id)" :title="data.name">{{data.name|ellipsis}}</a>
              </div>
              <div style="font-size: 12px;padding: 5px;float: left">
                <p style="margin-left: 5px"><i class="dot"></i>{{data.gameType}}</p>
                <p style="margin-left: 5px;margin-top: 5px"><i class="el-icon-time"></i>{{data.updateTime}}</p>
              </div>
            </li>
              <li v-if="datas.length==0" style="width: 90%">
                <p id="noFoundTipTag" style="color: gray;text-align: center;line-height: 200px">阿哦！~~ 游戏未找到，不要紧，点击<a @click="noFoundGame" style="cursor: pointer;color: blue;">这里</a>反馈，马上补上哦！ o((≥▽≤o)</p>
              </li>
            </ul>
        </div>
        <div style="width: 100%">
          <div class="block" style="margin: 0 auto;width: 720px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPageValue"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="15"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </el-main>

    <el-dialog title="游戏反馈" :visible.sync="dialogFormVisible" width="500px">
      <el-form>
        <el-form-item label="游戏名称" label-width="120px">
          <el-input v-model="needGame" style="width: 300px" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注（选填：问题描述）" label-width="120px">
          <el-input type="textarea" v-model="content" style="width: 300px" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveNeedGame">确 定</el-button>
      </div>
    </el-dialog>
  </el-container>

</template>
<script>
import { EventBus } from '../../event/event-bus.js';
export default {
  data() {
    return {
      totalCount:0,
      currentPageValue:1,
      hoverIndex:-1,
      isHovered: false,
      limit: 15,
      gameName: "",
      gameType: "",
      datas:{},
      content:"",
      needGame:"",
      dialogFormVisible:false,
      contentHeight:0,
      contentMargin:20,
      loading:false
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return '';
      if (value.length > 15) {
        return value.slice(0, 15) + '...'
      }
      return value
    },
  },
  // 卸载
  beforeDestroy() {
    EventBus.$off('someEvent', this.handleEvent);
  },
  beforeCreate(){
  },
  created() {
    let height = document.body.offsetHeight;
    this.contentHeight = height - 180
    let gameName = this.$route.query.gameName;
    let gameType = this.$route.query.gameType;
    this.gameName = gameName
    this.gameType = gameType
    this.loadList(this.limit,this.gameType,1)
  },
  mounted() {
    EventBus.$on('someEvent', this.handleEvent);
  },
  methods: {
    handleSizeChange(size){
        this.loadList(size,this.gameType,1)
    },
    handleCurrentChange(currentPage){
        this.loadList(this.limit,this.gameType,currentPage)
    },
    mouseoverImage(index){
      this.hoverIndex = index;
    },
    mouseleaveImage(){
      this.hoverIndex = -1;
    },
    handleEvent(data) {
      // 调用其他页面的方法
      this.gameType = data.gameType;
      this.gameName = data.gameName;
      this.loadList(this.limit,data.gameType,1);
    },
    goHome(){
      window.location.href='/'
    },
    loadList(limit,gameType,page){
      this.loading = true;
      this.$axios.get(this.$gameApiurls.gameList+"?name="+this.gameName+"&page="+page+"&limit="+limit+"&gameType="+gameType)
        .then((res) => {
          let data = res.data.list;
          this.totalCount = res.data.total;
          this.datas = data
          this.loading = false;
        });
    },goDetail(id){
        // 打开新窗口
        const newWindow = window.open('/gameDetail?gameId='+id);
        // 如果新窗口打开成功，则自动切换到该窗口
        if (newWindow) {
            newWindow.focus();
        }
    },goSearch(){
      this.loadList(this.limit,this.gameType,1)
    },noFoundGame(){
      this.needGame = "";
      this.dialogFormVisible = true
    },saveNeedGame(){
      this.$message({
        message: '已为您反馈【'+this.needGame+'】,请耐心等待！马上补上哦！',
        type: 'success'
      });
      this.$axios.post(this.$gameApiurls.saveNeedGame,{name:this.needGame,content:this.content})
        .then((res) => {
          let data = res.data.list;
          this.datas = data
        });
      this.dialogFormVisible = false
    }
  },
};
</script>

<style>
body {
  height: 100vh;
  background-color: #f4fafe;
}
#noFoundTipTag{
  text-align: left;
  padding: 10px;
}

.zoomed-in {
  transform: scale(1.2); /* 放大1.2倍 */
  transition: transform 0.3s ease; /* 动画过渡效果 */
}

.zoomed-out {
  transform: scale(1); /* 放大1.2倍 */
  transition: transform 0.3s ease; /* 动画过渡效果 */
}

a:hover{
  color:#1a284a;
  font-size: 15px;
}

.dot {
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
  background-color: #fd721f;
  display: inline-block;
}
.el-icon-circle-check{
  color: #FF9800;
  font-weight: bolder;
}

.el-main{
  padding: 0;
}

.input-class .el-input__inner {
  text-align: center;
  border-radius: 30px;
  width: 380px;
  float: right;
}

#gameListUl>li{
  float: left;
  list-style: none;
  height: 220px;
  width: 250px;
  border:1px solid #ccc;
  margin-top: 10px;
  margin-left: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 1px 4px 10px grey;
  overflow: hidden;
}

#headerUl>li{
  float: left;
  list-style: none;
}
</style>
